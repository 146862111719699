import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Login } from "./componentes/Login";
import { Centros } from "./componentes/Centros";
import AltaMedicos from "./componentes/AltaMedicos";
// import Combobox from "./componentes/Combobox";
// import { AltaMedicos } from "./componentes/Prueba";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/centros" element={<Centros />} />
        <Route exact path="/altas" element={<AltaMedicos />} />
      </Routes>
    </Router>
  );
}

export default App;
