import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { Login } from "./Login";
// import ComboBox from "./Combobox";

const AltaMedicos = () => {
  const [usuario, setUsuario] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [speciality, setSpeciality] = useState([]);
  const [selectedSpeciality, setSelectedSpeciality] = useState("");
  const [roles, setRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState("");
  const [loading, setLoading] = useState(true);
  const [medicosGuardados, setMedicosGuardados] = useState([]);

  const handleGuardarMedico = () => {
    const nuevoMedico = {
      email,
      name,
      lastName,
      password,
      speciality,
      roles,
    };

    setMedicosGuardados([...medicosGuardados, nuevoMedico]);

    // Restablecer los campos del formulario
    setEmail("");
    setName("");
    setLastName("");
    setPassword("");
    setSpeciality([]);
    setRoles([]);
  };

  useEffect(() => {
    const fetchSpeciality = async () => {
      try {
        const response = await fetch(
          "https://hc.neosolutions.com.ar/services/api/Specialty/getAll"
        );
        if (response.ok) {
          const data = await response.json();

          setSpeciality(data);
        } else {
          throw new Error("Error al obtener las especialidades");
        }
      } catch (error) {
        console.error(error);
      }
    };

    const fetchRoles = async () => {
      try {
        const response = await fetch(
          "https://hc.neosolutions.com.ar/services/api/Role/getRoles"
        );
        if (response.ok) {
          const data = await response.json();
          setRoles(data);
        } else {
          throw new Error("Error al obtener los roles");
        }
      } catch (error) {
        console.error(error);
      }
    };

    Promise.all([fetchSpeciality(), fetchRoles()]).then(() =>
      setLoading(false)
    );
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Crea el objeto para enviar al servidor
    const medico = {
      email,
      name,
      lastName,
      password,
      specialty: selectedSpeciality,
      role: selectedRoles,
      center: Cookies.get("uid"),
      key: Cookies.get("t")
    };

    try {
      console.log(medico);

      // Falta aqui hacer el fetch final...
      const response = await fetch('https://hc.neosolutions.com.ar/services/api/Register/User', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(medico),
      });

      if (response.ok) {
        // Éxito en el guardado del médico
        console.log("Médico guardado exitosamente");
      } else {
        throw new Error("Error al guardar el médico");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancel = () => {
    Cookies.remove("t");
    window.location.href = "/";
  };

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="container">
      <h3>Alta de Médicos</h3>
      <p>Administrador: {}</p>

      <div>
        <label>Email:</label>
        <input
          placeholder="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div>
        <label>Nombre:</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div>
        <label>Apellido:</label>
        <input
          type="text"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      </div>
      <div>
        <label>Contraseña:</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <div>
        <label>Especialidad:</label>
        <select
          onChange={(e) => setSelectedSpeciality(e.target.value)}
          placeholder="Seleccione especialidad">
          <option value="">Selección de Especialidad</option>
          {speciality.map((speciality, index) => (
            <option key={index} value={speciality.uid}>
              {speciality.name}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label>Rol:</label>
        <select onChange={(e) => setSelectedRoles(e.target.value)}>
        <option value="">Selección de Rol</option>
          {roles.map((roles, index) => (
            <option key={index} value={roles.uid}>
              {roles.name}
            </option>
          ))}
        </select>
      </div>
      <button type="submit" onClick={handleSubmit}>
        Guardar
      </button>
      <button type="button" onClick={handleCancel}>
        Cancelar
      </button>
    </div>
  );
};

export default AltaMedicos;
