// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    width: 100%;
    text-align: center;

    color: rgb(65, 65, 65);
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: #ffffff8b;
    margin-left: 25%;
    border-radius: 20px;
    border: 2px solid rgb(65, 65, 65);
    margin-top: 50px;
    width: 50%;
    color:black;
}
.container h3 {
    font-size: 30px;
}


.container-form {
    
    box-sizing: border-box;   
    align-items: left;

}
.label-input>label{
    font-size: 1em;
    min-width: 6em;
    display:inline-block;
    text-align: left;
}
.label-input input {
    border:none;
    border-bottom: 1px black solid;
    background:transparent;
    color:black;
   
    padding: 5px 5px 5px 5px;
    margin: 5px;
    font-size: 20px;


}
button
{
    background-color: rgb(68, 68, 88);
    color: white;
    border: 1px solid white;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
    font-size: 20px;
    cursor: pointer;
}

button:hover {
    background-color: white;
    color: rgb(68, 68, 88);
    border: 1px solid rgb(68, 68, 88);
}

`, "",{"version":3,"sources":["webpack://./src/Styles/login.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;;IAElB,sBAAsB;IACtB,kBAAkB;IAClB,qBAAqB;IACrB,2BAA2B;IAC3B,gBAAgB;IAChB,mBAAmB;IACnB,iCAAiC;IACjC,gBAAgB;IAChB,UAAU;IACV,WAAW;AACf;AACA;IACI,eAAe;AACnB;;;AAGA;;IAEI,sBAAsB;IACtB,iBAAiB;;AAErB;AACA;IACI,cAAc;IACd,cAAc;IACd,oBAAoB;IACpB,gBAAgB;AACpB;AACA;IACI,WAAW;IACX,8BAA8B;IAC9B,sBAAsB;IACtB,WAAW;;IAEX,wBAAwB;IACxB,WAAW;IACX,eAAe;;;AAGnB;AACA;;IAEI,iCAAiC;IACjC,YAAY;IACZ,uBAAuB;IACvB,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,uBAAuB;IACvB,sBAAsB;IACtB,iCAAiC;AACrC","sourcesContent":[".container {\n    width: 100%;\n    text-align: center;\n\n    color: rgb(65, 65, 65);\n    padding-top: 100px;\n    padding-bottom: 100px;\n    background-color: #ffffff8b;\n    margin-left: 25%;\n    border-radius: 20px;\n    border: 2px solid rgb(65, 65, 65);\n    margin-top: 50px;\n    width: 50%;\n    color:black;\n}\n.container h3 {\n    font-size: 30px;\n}\n\n\n.container-form {\n    \n    box-sizing: border-box;   \n    align-items: left;\n\n}\n.label-input>label{\n    font-size: 1em;\n    min-width: 6em;\n    display:inline-block;\n    text-align: left;\n}\n.label-input input {\n    border:none;\n    border-bottom: 1px black solid;\n    background:transparent;\n    color:black;\n   \n    padding: 5px 5px 5px 5px;\n    margin: 5px;\n    font-size: 20px;\n\n\n}\nbutton\n{\n    background-color: rgb(68, 68, 88);\n    color: white;\n    border: 1px solid white;\n    border-radius: 5px;\n    padding: 5px;\n    margin: 5px;\n    font-size: 20px;\n    cursor: pointer;\n}\n\nbutton:hover {\n    background-color: white;\n    color: rgb(68, 68, 88);\n    border: 1px solid rgb(68, 68, 88);\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
