import React, { useState } from "react";
import Cookies from "js-cookie";
import "../Styles/login.css";
// import ComboBox from './Combobox';

export const Login = ({ handleUsuario }) => {
  const [usr, setUsr] = useState("");
  const [pwd, setPwd] = useState("");
  const [data, setData] = useState(null);

  //para guardar el valor del input usr 
  const handleUsrChange = (event) => {
    const valorUsuario = event.target.value;
    setUsr(valorUsuario);
    handleUsuario(valorUsuario);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        "https://hc.neosolutions.com.ar/services/api/login/login",
        {
          method: "POST",
          body: JSON.stringify({ usr, pwd }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const jsonData = await response.json();
        setData(jsonData);
        console.log(jsonData);
        const token = jsonData.token;
        // const userText = setUsr;
        Cookies.set("t", token.key);
        // Cookies.set('usr', setUsr(e)); //Ver guardar usr en una cookie
        window.location.href = "/centros";
      } else {
        throw new Error("Error en la solicitud");
      }
    } catch (error) {
      alert("Error en la solicitud");
      window.location.href = "/";
    }
  };

  return (
    <div className="container">
      <h3>Login</h3>
      <form className="container-form" onSubmit={handleSubmit}>
        <div className="label-input">
          <label htmlFor="nombre">Usuario: </label>
          <input
            type="text"
            onChange={(e) => setUsr(e.target.value)}
            value={usr}
          />
        </div>
        <div className="label-input">
          <label htmlFor="nombre">Password: </label>
          <input
            type="password"
            onChange={(e) => setPwd(e.target.value)}
            value={pwd}
          />
        </div>
        <button type="submit">Iniciar Sesión</button>
      </form>
      {/* <div>
      <label htmlFor="nombre">Combobox: </label>
      <ComboBox apiUrl="https://hc.neosolutions.com.ar/services/api/Role/GetRoles" /> 
      </div> */}
    </div>
  );
};