import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import "../Styles/centros.css"

export const Centros = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const tokenCookie = document.cookie
    .split("; ")
    .find((row) => row.startsWith("t="));
  let tokenKey = null;

  if (tokenCookie) {
    tokenKey = tokenCookie.split("=")[1];
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tokenkey = Cookies.get("t");
        const response = await fetch(
          "https://hc.neosolutions.com.ar/services/api/user/getCenters",
          {
            method: "POST",
            body: JSON.stringify({ key: tokenkey }),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const jsonData = await response.json();
          setData(jsonData);
          const uid = jsonData[0].uid;

          setLoading(false);
        } else {
          throw new Error("Error en la solicitud");
        }
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const onChangeHandle = (val) => {
    Cookies.set("uid", val);
    window.location.href = "/altas";
  };

  if (loading) {
    return <div>Cargando...</div>;
  }

  if (error) {
    return (window.location.href = "/"), alert("Su sesion a caducado"), 
    Cookies.remove("t");
  }

  return (
    <div className="container">
      <h3>Centros Médicos</h3>
      <label>Centros Disponibles: </label>
      <select onChange={(e) => onChangeHandle(e.target.value)}  placeholder="Seleccionar Centro">
      <option value="">Selección de centro</option>
        {data.map((centro) => (
          <option key={Cookies.get("t")} value={centro.uid}>
            {centro.name}
          </option>
        ))}
      </select>
    </div>
  );
};
